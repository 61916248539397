<template>
  <div class="contents">
    <PromoBanner class="container mb-5 xl:min-h-[450px]" />
    <AppSectionsSummaryInfo class="container hidden md:flex mb-5" />

    <AppSectionsCartMini
      class="container mb-5 md:mb-[90px] 2xl:mb-12"
      :cart-list="productList"
      :count="count"
      :total-cost="totalCost"
      :show="showMini"
      @close="cartStore.closeMiniCart"
    />

    <TheCatalog />

    <div class="container space-y-[40px] md:space-y-[100px] pb-5 md:pb-[90px]">
      <!--      <client-only>-->
      <!--        <LazyListWatchedProducts />-->
      <!--      </client-only>-->
      <!--      <ListWatchedProducts />-->
      <LazyListProducts
        show-categories
        type="ordered"
        class="min-h-[450px]"
        :list-link="{
          label: t('Всі товари')
        }"
      />

      <LazyListProducts
        show-categories
        type="promotion"
        class="min-h-[450px]"
        :list-link="{
          label: t('Всі товари')
        }"
      />

      <LazyListProducts
        show-categories
        type="new"
        class="min-h-[450px]"
        :list-link="{
          label: t('Всі товари')
        }"
      />

      <LazyNewsListHorizontal
        :title="t('block_posts_title')"
        :list-link="{
          label: 'Всі новини',
          action: () => navigateTo(localePath('/post/'))
        }"
      />

      <LazyReviewListHorizontal :title="t('Відгуки наших клієнтів')" />

      <LazyReviewListHorizontalHomeExpertReviews class="mb-10 md:mb-[90px]" />

      <div class="space-y-10 md:space-y-12">
        <LazyAppSectionsCompanyDirection :block-data="companyDirectionBlock" />
        <div v-html="threeCardsBlock?.content"></div>
      </div>

      <LazyListBrands />
      <LazyAppSectionsYoutube />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useCartStore } from '~/stores/cart'

const { t, locale } = useI18n()
const cartStore = useCartStore()
const { productList, count, totalCost, showMini } = storeToRefs(cartStore)

const { data } = useAsyncData('main-page', () => {
  return useApi().cms.getPage('main-page')
})

const { data: seoData } = await useAsyncData(
  'home-page-seo-' + locale.value,
  () => useApi().seo.getByReferer()
)
useHead({
  title: seoData.value?.title || t('default_seo_title'),
  link: [
    {
      rel: 'canonical',
      href: useCanonical(seoData.value?.canonical) || '/'
    }
  ],
  meta: [
    {
      name: 'description',
      content: seoData.value?.description || t('default_seo_description')
    },
    {
      name: 'robots',
      content: seoData.value?.robots || 'index, follow'
    },
    {
      property: 'og:title',
      content: seoData.value?.title || t('default_seo_title')
    },
    {
      property: 'og:description',
      content: seoData.value?.description || t('default_seo_title')
    },
    {
      property: 'og:image',
      content: `${useRequestURL().origin}/images/ibis.png`
    },
    {
      property: 'og:type',
      content: 'article'
    },
    {
      property: 'og:url',
      content: useCanonical(seoData.value?.canonical) || '/'
    }
  ]
})

const companyDirectionBlock = computed(() => {
  return (
    data.value && {
      weapon: data.value?.blocks?.find(
        ({ code }) => code === 'main_page_about_1'
      ),
      fishing: data.value?.blocks?.find(
        ({ code }) => code === 'main_page_about_2'
      )
    }
  )
})

const threeCardsBlock = computed(() =>
  data.value?.blocks?.find(({ code }) => code === 'main_page_about_3')
)

onMounted(() => {
  console.info(useRuntimeConfig().public.appVersion || '')
})
</script>
